@import "styles/common.scss";
@mixin tick-icon($color) {
  background: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.78154 5.59994L7.96076 1.35544C8.15317 1.16003 8.46831 1.16003 8.66071 1.35544C8.84894 1.54661 8.84894 1.85345 8.66071 2.04462L3.78154 7L1.33929 4.5196C1.15106 4.32843 1.15106 4.02159 1.33929 3.83042C1.53169 3.63501 1.84683 3.63501 2.03924 3.83042L3.78154 5.59994Z' fill='#{$color}' stroke='#{$color}'/%3E%3C/svg%3E%0A")
    no-repeat;
}
.page-content{
  position: relative;
  height: 100%;
  @include flex-column;
  overflow-x: hidden;
}
.header {
  @include flex(center);
  column-gap: 10px;
  position: relative;
  height: 64px;
  &.navHeader {
    @include flex-center;
    @include f-inter-bold(1.6rem,2.2rem);
    padding: 16px 0 ;
    .icon{
      position: absolute;
      left: 10px;
      background: none;
    }
  }
  &.hideHeader {
    display: none;
  }
  .wrapperLogoMatches {
    width: 100%;
    cursor: pointer;
    @include flex(center, space-between);

    padding: 8px 40px;
    gap: 8px;
    flex-shrink: 0;
  }
  .wrapperLogo {
    cursor: pointer;
    @include flex(center);

    padding: 8px 40px;
    gap: 8px;
    flex-shrink: 0;
  }
  .savingWrapper {
    @include flex(Center, center);
    min-width: 64px;
    padding: 7px 12px;
    gap: 6px;
    border-radius: 99px;
    background: rgba(8, 12, 11, 0.05);
    .spinner1 {
      width: 15px;
      height: 15px;
    }
    .savingText {
      color: #080c0b;
      @include f-inter-semi-bold(1.3rem, 1.8rem);
    }
  }
  .pref {
    color: #080c0b;
    @include f-inter-semi-bold(1.8rem, 2.5rem);
  }
  .navMenu {
    position: absolute;
    left: 10px;

    &Trigger {
        border: none;
        background: inherit;
        box-shadow: none;
    }

    &List {
        right: unset;
        top: 52px;
    }
  }
}

.progressBarHeader {
  position: fixed;
  width: 100%;
  z-index: $zindex-fixed;
  @include mobile {
    height: 6px;
    top:0;
  }
  @include desktop {
    top: 72px;
  height: 3px;}
}

.content-contianer{
  width: 100%;
}

.container {
  @include flex-column(center,null);
  // flex: 1;
  height: 100%;
overflow: auto;

position: relative;
  @include mobile {
    height: calc(100% - 48px);
  }
  @include desktop {
    column-gap: 64px;
  }
  .sidebar{
    position: fixed;
    top: 112px;
    height: 380px;
    @include hide-small();
    @include large {
      left: 240px;
      width: 280px;

    }
    @include medium{
      left: 80px;
      width: 240px
    }
  }
 
  
  .textarea {
    border: 1.5px solid var(--border-on-lights-15);
    overflow: hidden;
    min-width: 224px;
    &:hover {
      border: 1.5px solid rgba(8, 12, 11, 0.3);
    }
  }
  .wrapper {
    @include flex-column;
    .contentWrapper {
      @include mobile {
        padding: 60px 16px 0px;
      }
      @include desktop {
        padding: 40px 0 0px;
        width: 456px;
      }
      .setup {
        @include f-inter-bold(2.4rem, 3.2rem);
        letter-spacing: -0.48px;
      }
    }
}
  .content {
    .header {
      @include flex-center;
      @include medium {
        padding: 20px 0;
        height: 64px;
      }
      @include small {
        padding: 8px 0;
        height: 60px;
      }
      @include large {
        padding: 20px 0;
        height: 80px;
      }
      .navigation {
        @include flex(center, space-between);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        @include sm-desktop-down {
          left: 0;
        }
        @include sm-desktop-up {
          left: -10px;
        }
        .doneButton {
          @include f-inter-semi-bold(1.3rem, 1.6rem);
          color: var(--dark-grey);
          @include sm-desktop-down {
            padding-right: 10px;
          }
          @include sm-desktop-up {
            padding: 0;
          }
          &Disabled {
            cursor: default;
            background: none;
            opacity: 0.6;
            @include sm-desktop-up {
              pointer-events: none;
            }
          }
        }
      }
      .progressBar {
        width: 17%;
      }
      .title {
        @include f-inter-bold(1.7rem, 2.1rem);
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include sm-desktop-down {
          width: 210px;
        }
        @include sm-desktop-up {
          width: 275px;
        }
        &.hide {
          width: 0;
          opacity: 0;
          transition: opacity 0.25s ease-in-out;
          pointer-events: none;
        }
      }
      .minisearch {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
        color: var(--dark-grey);
        &.hide {
          opacity: 0;
          transition: opacity 0.25s ease-in-out;
          pointer-events: none;
        }
        &.open {
          background-color: rgba(100, 101, 104, 0.1);
          border-radius: 8px;
        }
        @include small {
          display: none;
        }
      }
      position: absolute;
      top: 0;
      z-index: $zindex-sticky;
      background: var(--bg-primary-light);
    }
    @include medium {
      width: 456px;
      padding: 40px 0 120px;
      min-height: calc(100vh - 80px);
      .header {
        width: 352px;
      }
    }
    @include small {
      padding: 32px 16px 120px;
      min-height: 100vh;
      &,
      .header {
        width: 100%;
      }
      .header {
        left: 0;
        transform: unset;
      }
    }
    @include large {
      padding: 40px 0 120px;
      min-height: calc(100vh - 80px);
      width: 456px;
      .header {
        width: 456px;
      }
    }
    &Location {
      @include medium {
        padding-bottom: 160px;
      }
      @include small {
        padding-bottom: 150px;
      }
      @include large {
        padding-bottom: 80px;
      }
    }
    .chevron {
      padding: 0;
      &Disabled {
        cursor: default;
        color: rgba(9, 20, 37, 0.3);
        @include sm-desktop-up {
          pointer-events: none;
        }
      }
    }
    .step {
      @include f-inter-regular(1.7rem, 2rem);
      padding: 0 8px;
    }
    .setup {
      @include f-inter-bold(2.4rem, 3.2rem);
      letter-spacing: -0.48px;
      .optionalText {
        @include f-inter-semi-bold(2.4rem, 3.2rem);
        letter-spacing: -0.72px;

        color: var(--text_icon-placeholder);
      }
    }
    .industryHeading {
      @include f-inter-semi-bold(1.8rem, 2.5rem);
      color: var(--text-primary-v2);
    }

    .questionText {
      @include f-inter-regular-2(1.4rem, 2rem);
      color: var(--surface-inverted);
      div:first-child {
        span:first-child { 
          @include f-inter-regular(1.2rem , 1.5rem);
          color: var(--text-icon-tertiary);
        }
      }
    }

    .tooltip {
      &Container {
        width: 370px;
        padding: 8px 12px;
        border-radius: 6px;
        @include f-inter-regular(1.2, 1.7);
        white-space: pre-line;
        @include small {
          max-width: 300px;
        }
        &Content {
          text-align: left;
        }
        &Icon {
          margin-left: 2px;
          padding: 3px;
          width: 20px;
          height: 20px;
          color: var(--text-icon-on-lights-tertiary);
        }
      }
    }
    .sectionContainer {
      @include flex();
      @include mobile{
        @include flex-column()
      }
      gap: 8px;
      .dropdown3{
        min-width: auto;
      }

      .ethnicityContainer {
        width: 100%;
        .MultiselectLabel {
          @include flex;
          gap: 4px;
          .optional {
            @include f-inter-regular(1.2rem, 1.5rem);
            position: relative;
            top: 3px;
            color: var(--text-icon-tertiary);
          }
        }
        .dropdown {
          margin-top: 4px;

          .multiselectButton {
            border: 1.5px solid rgba(8, 12, 11, 0.15);
            padding: 11px 14px 11px 14px;
          }
          .multiDropdownTrigger {
            color: #080c0b;
            @include f-inter-regular(1.6rem, 2.6rem);
          }
        }
      }
    }
    .desc {
      margin: 8px 0;
      @include f-inter-regular(1.6rem, 2.6rem);
      letter-spacing: -0.176px;
      mark {
        font-weight: 600;
        background: none;
      }
      .tick {
        font-size: 24px;
        padding: 0 2px;
      }
      &Row {
        column-gap: 10px;
        background: var(--surface-overlay-minimal);
        border-radius: 12px;
        padding: 16px;
        color: rgba(8, 12, 11, 0.74);
      }
    }
    .hide-mobile {
      @include hide-small()
    }

    .university {
      margin-top: 20px;
    }
    .dateContainer {
      flex-wrap: nowrap;
      gap: 8px;
    }

    .label {
      @include f-inter-regular(1.4rem, 1.6rem);
      color: rgba(9, 20, 37, 0.74);
      margin-bottom: 5px;
    }
    .invalidInput {
      input {
        &,
        &:focus {
          border: 1px solid rgba(9, 20, 37, 0.2);
        }
      }
    }
    .search {
      margin-top: 24px;
      margin-bottom: 20px;
      position: sticky;
      @include medium {
        &.sticky {
          top: 64px;
          z-index: $zindex-sticky;
          background-color: var(--bg-primary-light);
          padding-bottom: 8px;
        }
      }
      @include small {
        &,
        &.sticky {
          top: 60px;
          z-index: $zindex-sticky;
          padding-bottom: 8px;
        }
      }
      @include large {
        &.sticky {
          top: 80px;
          z-index: $zindex-sticky;
          background-color: var(--bg-primary-light);
          padding-bottom: 8px;
        }
      }
    }
    .selectAll {
      @include f-inter-semi-bold(1.3rem, 1.5rem);
      color: var(--dark-grey);
      padding: 0;
      @include mobile {
        padding-right: 16px;
      }
    }
    .date {
      caret-color: transparent;
      @include mobile {
        width: 100%;
      }

      @include desktop {
        flex: 1 1;
      }

      &Disabled {
        color: rgba(8, 12, 11, 0.3);
        margin-bottom: 5px;

        &Text {
          color: rgba(8, 12, 11, 0.3);
          height: 48px;
          padding: 15px 14px;
          border-radius: 10px;
          background: rgba(239, 241, 243, 0.97);
        }
      }

      &Container {
        column-gap: 8px;

        .textarea {
          border: 1.5px solid var(--border-on-lights-15);
          overflow: hidden;

          &:hover {
            border: 1.5px solid rgba(8, 12, 11, 0.3);
          }
        }

        @include desktop {
          .left {
            left: 0;
            right: auto;
          }
          .bottom {
            bottom: 70%;
            top: auto;
          }
        }
        @include mobile {
          @include flex-column;
        }
      }
    }
    .gap {
      margin-top: 32px;
    }
    .errorGap {
      margin-bottom: 4px;
    }
    .ProfileModalCheckbox {
      margin-top: 20px;
    }
    .dateContainer {
      margin-top: 24px;
    }
    .match {
      background: var(--ui-teal-light-surface-tertiary);
      &:hover {
        background: var(--ui-teal-light-surface-tertiary-hover);
      }
      &Card {
        position: relative;
        border-radius: 12px;
        margin-bottom: 8px;
        @include sm-desktop-down {
          width: calc(100vw - 32px);
        }

        &:last-of-type {
          margin-bottom: 0;
        }
        .checkbox {
          position: absolute;
          top: 20px;
          right: 20px;
          & > input {
            width: 24px;
            height: 24px;
            cursor: pointer;
            &:not(:checked) {
              background-color: var(--ui-teal-light-bg-primary);
              border: 1.5px solid rgba(8, 12, 11, 0.3);
              box-shadow: 0px 1px 1px rgba(8, 12, 11, 0.04);
              border-radius: 5px;
            }
            &:checked {
              @include tick-icon('%23ffffff');

              padding: 0;
              border: 0;
              background-color: var(--primary-color);
              background-position: left 50% top 50%;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
    .counts {
      &Row {
        @include f-inter-regular(1.4rem, 2rem);
        padding: 12px 16px 8px 16px;
      }
      &Button {
        @include f-inter-regular-2(1.4rem, 2rem);
        padding: 0;
      }
    }
  }
  .content-width-society{
    margin: 0 auto;
    @include large  {
      width: 540px;
    }
    @include medium{
      width: 540px;
    }
  }
  
  
  .content-mp{
    // todo check
    // @include hide-scrollbar();
    // overflow: auto;
    @include desktop {
      height: calc(100% - 72px);
      margin-left: 344px;
      padding-bottom: 80px;
    }
  }
  .event-content {
    @include mobile {
      padding-bottom: 200px;
    }
  }

  .search {
    input {
      border-radius: 20px;
      border: none;
      background: rgba(100, 101, 104, 0.1);
      padding: 12px 42px;
      @include f-inter-regular(1.6rem, 2rem);
      &:focus {
        border: none;
        caret-color: var(--primary-color);
      }
    }
  }
  .modal {
    &Content {
      @include medium {
        padding: 15px 28px;
        width: 100%;
      }
      @include small {
        flex: 1;
        padding: 0;
      }
      @include large {
        padding: 15px 28px;
        width: 100%;
      }
      &Course {
        background: var(--bg-primary-webapp);
      }
    }
    &Checkbox {
      margin-top: 24px;
      label {
        @include f-inter-regular(1.4rem, 2rem);
        margin: auto 0 auto 8px;
      }
    }
    &Title {
      @include f-inter-semi-bold(1.6rem, 1.8rem);
      text-align: center;
    }
    &Desc {
      @include f-inter-regular(1.5rem, 2.1rem);
      padding: 16px 0;
      color: var(--dark-grey);
      mark {
        font-weight: 700;
        background: none;
      }
    }

    &Search {
      margin: 8px 0 24px;
    }
  }
  .checkboxes,
  .checkboxesLocation {
    @include flex-column;
    row-gap: 8px;
    column-gap: 8px;
    &Disabled {
      input:not(:checked) {
        border: 1px solid rgba(9, 20, 37, 0.1);
        cursor: default;
        pointer-events: none;
      }
      label {
        color: #a1a3a4;
      }
    }
    &Label {
      width: 100%;
    }
  }
  .checkboxes {
    margin-top: 16px;
  }
  .checkboxShowIndustries {
    @include flex-column();
    margin-top: 32px;
    width: 100%;
    gap: 8px;
    label {
      margin: auto;
    }
    .checkBoxShowIndustry {
      padding: 13px 20px;
      label{
        color: var(--text-primary-v2);

      }
    }
    
  }
  .industry-height{
    min-height: calc(100vh - 80px);
  }


  .checkboxesResponsibility {
    display: block;

    .checkboxResponsibility {
      height: 48px;
      margin-bottom: 8px;
      border-radius: 17px;
      margin-right: 8px;
      & > label {
        color: var(--text-icon-on-lights-secondary);
      }
    }
  }
  .checkboxesLocationRow {
    flex-direction: column;
    cursor: pointer;
  }

  .selectionbox {
    border-radius: 14px;
    padding: 13px 16px;
    & > label {
      font-weight: 600;
      color: var(--text-icon-on-lights-secondary);
    }
    & > input {
      border: 1px solid rgba(9, 20, 37, 0.1);
      border-radius: 14px;

      &:checked {
        border-color: var(--primary-color);
        border-width: 2px;
        & ~ label {
          color: var(--primary-color);
        }
      }
    }
  }
  .selectionboxOpportunityOnboarding {
    padding: 14px 16px;
    min-height: 72px;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 0px rgba(8, 12, 11, 0.04);
    &:hover {
      box-shadow: 0px 2px 3px 0px rgba(8, 12, 11, 0.06);
    }
    &:first-of-type {
      margin-top: 16px;
      @include mobile {
        margin-top: 8px;
      }
    }
    & > label {
      width: 100%;
      color: var(--text-icon-on-lights-tertiary);
    }
    & > input {
      border-width: 0;
      border-radius: 12px;
      
      &:checked ~ label > .display-name-dark {
        color: var(--primary-color);
      }
    }
  }
  .remote {
    margin: 24px 0 8px;
  }
  .labelDesc {
    @include f-inter-regular(1.4rem, 2rem);
    width: 92.5%;
    letter-spacing: -0.14px;
  }
  .displayname {
    font-weight: 600;
  }
  .display-name-dark { 
    color: var(--text_icon-primary);
  }
  .notificationStubContainer {
    @include flex();
    gap: 8px;
    margin-top: 16px;
    padding: 12px 16px;
    border-radius: 16px;
    border: 0.5px solid var(--border-on-lights-10);
    background: var(--surface-warning-silent);

    box-shadow: 0px 1px 2px 0px var(--black-color-04);
    backdrop-filter: blur(50px);
    .iconInfo {
      align-items: flex-start;

      svg {
        height: 22px;
        width: 22px;
      }
    }
    .notificationHeading {
      color: var(--surface-inverted);
      @include f-inter-semi-bold(1.5rem, 2.1rem);
    }
    .notificationDesc {
      color: var(--surface-inverted);
      @include f-inter-regular(1.3rem, 1.8rem);
    }

  }
  .form,
  .section {
    padding-top: 32px;
  }
  .input{
    &:last-of-type{
      @include mobile {
      margin-top: 24px;
    }}
    &Label {
    @include f-inter-regular-2(1.4rem, 2rem);
    color: var(--text_icon-primary);
    }
  }
  
  .nameContainer {
   
    @include desktop {
    @include flex();
    gap: 8px;
  }
  }

  .question {
    margin-top: 24px;
  }
  .options{
    top: calc(100% - 340px);
  }

  .link {
    &Gap {
      margin-top: 8px;
    }
    &Text {
      @include f-inter-regular-2(1.3rem, 1.8rem);
      color: var(--link-color);
      cursor: pointer;
    }
  }
  .tooltip {
    &Container {
      width: 236px;
      white-space: pre-line;
    }
    position: absolute;
    @include medium {
      width: 350px;
      bottom: 32px;
    }
    @include small {
      width: calc(100% - 32px);
      bottom: 24px;
      left: 16px;
    }
    @include large {
      width: 280px;
      bottom: 48px;
      left: calc(50% - 140px);
    }
  }
  .titleSpacing {
    margin-bottom: 24px;
  }

  .tick {
    color: var(--primary-color);
    margin-top: 5px;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  .dashDivider {
    width: 100%;
    height: 1px;
    border: 1px dashed #ced0d4;
    margin: 18px 0px;
  }
  .radio {
    border-radius: 14px;
    box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);
    padding: 13px;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
    & > input {
      &:checked {
        border-radius: 14px;
        border: 2px solid var(--primary-color);
        & ~ label {
          color: var(--primary-color);
        }
      }
    }
    & > label {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      color: var(--text-icon-on-lights-secondary);
    }
  }
}

.button {
  @include flex(center, flex-end);
  padding: 28px;
}

.spinner {
  position: absolute;
  top: 50%;
  right: 50%;
}

.searchDesc {
  margin-top: 8px;
  @include f-inter-regular(1.6rem, 2.5rem);
  mark {
    font-weight: 700;
    background: none;
  }
  &Row {
    column-gap: 8px;
    background: #f8f9fa;
    border-radius: 12px;
    padding: 16px;
    color: rgba(8, 12, 11, 0.74);
  }
}

.aiOnboardingButton {
  position: fixed;
  width: 336px;
  bottom: 48px;
  left: calc(50% - 168px);
}
.secondary {
  padding: 14px 0;
  margin-top: 32px;
  margin-bottom: 16px;
}
.errorMessage {
  @include flex(center);
  gap: 5px;
  color: var(--text_icon-on_lights-critical);
  &Icon {
    width: 15px;
    height: 15px;
    color: var(--text_icon-on_lights-critical);
  }
}

.container-society{
  @include flex-column(normal,null);
  @include scroll-bar-style();
  flex: 1;
}

.modal {
  &Checkboxes {
    background: var(--ui-teal-light-bg-primary);
    border-radius: 14px;
    flex-direction: column;
    @include desktop {
      padding: 16px;
    }
    &Row {
      flex-direction: column;
      cursor: pointer;
      .separator {
        border: none;
        border-bottom: 1px dashed #ced0d4;
        padding: 0 16px;
        width: 100%;
      }
      &:last-of-type {
        hr {
          display: none;
        }
      }
    }
  }
  &Selectionbox {
    border-radius: 14px;
    padding: 10px 0;
    & > input {
      border: none;
    }
  }
}

.fullWidth {
  width: 100%;
}
